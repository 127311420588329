import React, { useState } from "react";
import "./Card.css";
import { useNavigate } from 'react-router-dom'
const Card = ({emoji, heading, detail,price, color}) => {
  const history = useNavigate();
  const [itemDetails,setItemDetails]=useState(heading);
  const [itemPrice,setItemPrice]=useState(price*100);
  const handleClick = () => {
    // Set the value in useState
    setItemDetails(heading);
    setItemPrice(price*100)
    // Redirect to another page
    history("/checkout",{state: { itemDetails: itemDetails,itemPrice:itemPrice }});
  };

  return (
    <>
    <div className="card" style={{borderColor: {color}}}> 
      <img height={'170px'} src={emoji} alt="" />
      <span>{heading}</span>
      <span>{detail}</span>
      <span>Price: Rs.{price}</span>
      <button onClick={handleClick} className="c-button">BUY NOW</button>
    </div>
    </>
  );
};

export default Card;
