import React, { useEffect } from 'react';
import './Checkout.css';
import { useLocation } from 'react-router-dom'
const Checkout = () => {
  const location = useLocation();
      const heading = location.state ? location.state.itemDetails : '';
      const price = location.state ? location.state.itemPrice : '';
      useEffect(() => {
        window.scrollTo(0, 0);
    });

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <form action='https://api.evokecode.com/astro75/razorpay' method='post'>
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="creditCard">Phone No:</label>
        <input type="text" id="creditCard" name="phone" required />

        <input type="hidden" id="creditCard" name='payment' value={price} required />
        <input type="hidden" id="creditCard" name='description' value={heading} required />

        <button type="submit">Checkout</button>
      </form>
    </div>
  );
};

export default Checkout;